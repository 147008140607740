//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data: function () {
  return {   
      componentKey:0,
      show:false,
      isEditable: false,
      profiles: [{type:'ADMIN',name:'Administrador'},{type:'OPER',name:'Operador'} ],
      rules: {
        required: value => !!value || 'Required.'
      }, 
      users: {},

      user: {},

      error_show:false,
      error_message:[],

      faenas: [],



      show_form: false,
      pagination_options: {},
      serverItemsLength: 4,

      headers: [

        {
          text: 'Rut',
          align: 'left',
          sortable: false,
          value: 'rut',
        },

        { text: 'Nombres', value: 'firstName' },
        { text: 'Apellidos', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Celular', value: 'phone' },
        { text: 'Perfil', value: 'profile', align:'center' ,sortable: false },
        { text: 'Acción', value: 'action', align:'center' ,sortable: false }
        
      ],

    }
  },
  created() {
    console.log('create -> [Usuarios]');

    this.getList();

  },
  computed: {
    
  },
  methods: {
      forceRerender() {
            this.componentKey += 1;  
      },

      getList() {
        this.$store.state.ts.loader.show = true;
        this.$axios.all([
          this.$axios.get('/api/user/', {id_faena: '0'} ),
          this.$axios.get('/api/faena/', {id_faena: '0'} ),
        ])
        .then(this.$axios.spread((user_resp, faena_resp)  =>{

          console.log(user_resp.data);
          console.log(faena_resp.data);

          this.users = user_resp.data;
          this.faenas = faena_resp.data;
          
          this.$store.state.ts.loader.show = false;
          this.show =true;

        }))
        .catch((user_err, faena_err) => {
          console.log(user_err);
          console.log(faena_err);
        });
      },


      newUser: function() {        
        this.isEditable = true;
        this.user = {id:null,rut:''};
        this.show_form  = true;
        this.error_show = false;
      },

      editItem: function(item) {
        this.$util.log(item);
        this.isEditable = true;
        this.user = this.$util.clone(item);
        this.show_form  = true;
        this.error_show = false;
      },


      deleteItem: function(item) {
        this.$bus.$emit('ts-confirm',{type:'success',confirm:true,text:['Seguro que desea borrar el usario '+item.email+' ?'],
          cbr:function() {
            this.$axios.delete('/api/user/',{data:{id:item.id}}).then(resp => {
              this.$util.log('delete ok..... '+item.id);
              this.$util.log(resp);
              this.getList();
            })
            .catch(e => {
                this.$util.log(e);
                this.$bus.$emit('ts-confirm',{type:'error',text:['No se pudo borrar el usario '+item.email+'.']});
            });
          }.bind(this)
        });
      },

      cancelUser: function() {
        this.show_form  = false;
      },

      
      saveUser: function() {

        // New
        if (this.user.id===null) {
          this.$axios.post('/api/user/',this.user).then(resp => {
            console.log(resp);
            this.$bus.$emit('ts-confirm',{type:'success',text:['El usario fue creado.'],
              cbr:function() { 
                this.show_form  = false;
                this.getList();
              }.bind(this)
            });

          })
          .catch(e => {
            this.error_message = ['Revise los datos de su formulario.']
            if (e.response.status==400) {
              console.log(e.response.data.errors);
              this.error_message = e.response.data.errors;
              
            }
            this.error_show = true;
          }); 
        }
        // Update
        else {
          this.$axios.patch('/api/user/',this.user).then(resp => {
            console.log(resp);
            this.$bus.$emit('ts-confirm',{type:'success',text:['El usario '+this.user.email+' fue actualizado.'],
              cbr:function() { 
                this.show_form  = false;
                this.getList();
              }.bind(this)
            });

          })
          .catch(e => {
            this.error_message = ['Revise los datos de su formulario.']
            if (e.response.status==400) {
              console.log(e.response.data.errors);
              this.error_message = e.response.data.errors; 
            }
            this.error_show = true;
          });          
        }
      
      }

  }
};
